import React from 'react'
import styled from 'styled-components'
import MyRoomBg from '../../../assets/decorate-my-room/MyRoom.png'
import MyDecorBg from '../../../assets/decorate-my-room/MyDecor.png'
import MyEventBg from '../../../assets/decorate-my-room/myEventBg.png'
import RemoveBg from '../../../assets/decorate-my-room/BGEraser.png'
import ARBg from '../../../assets/decorate-my-room/ARRuler.png'
import DBg from '../../../assets/decorate-my-room/3DModel.png'
import ArViewBg from '../../../assets/decorate-my-room/ARView.png'
import UploadIcon from '../../../assets/decorate-my-room/i_upload.svg'
import RemoveIcon from '../../../assets/decorate-my-room/i_remove.svg'
import ARIcon from '../../../assets/decorate-my-room/i_ar.svg'
import DIcon from '../../../assets/decorate-my-room/i_3d.svg'
import ArViewIcon from '../../../assets/decorate-my-room/arViewIcon.svg'
import MembershipIcon from '../../../assets/badge_membership_on.png'
import BecomeMemberBg from '../../../assets/decorate-my-room/BecomeMember.png'
import { useRouter as useHistory } from 'next/router'
import Image from 'next/image'
import { useDispatch, useSelector } from 'react-redux'
import { setShowMembershipModal } from '../../../redux/designerReducers/designerReducer'
import { gotoSignUp } from '../../../redux/actions/AuthActions'
import { logEvent } from '../../../utils/firebase'

const Card = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 10px;
  flex: 0 0 auto;
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;
  overflow: hidden;

  @media (max-width: 1200px) {
    width: 240px;
    height: 240px;
  }

  @media (max-width: 800px) {
    width: 140px;
    height: 140px;
  }
`

const Title = styled.div`
  color: #ffffff;
  text-align: center;
  margin: 0;
  font-size: 28px;
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -10px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  width: 100%;
  max-width: 280px;

  @media only screen and (max-width: 800px) {
    font-size: 16px;
    padding-top: 10px;
  }
`

const Description = styled.span`
  position: absolute;
  font-weight: 400;
  font-size: 18px;
  color: #1d6d75;
  width: 100%;
  text-align: center;
  bottom: 100px;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 800px) {
    bottom: 37px;
    white-space: nowrap;
    font-size: 14px;
  }
`

const Membership = styled.div`
  position: absolute;
  top: 32px;
  display: flex;
  align-items: center;
  width: 180px;
  height: 40px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0px 30px 30px 0px;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;

  @media only screen and (max-width: 1020px) {
    height: 27px;
    width: 140px;
    font-size: 14px;
    top: 28px;

    & img {
      width: 15px;
      height: 15px;
    }
  }

  @media only screen and (max-width: 800px) {
    height: 20px;
    width: 112px;
    top: 12px;
    font-size: 10px;
    white-space: nowrap;
  }

  & img {
    height: 26px;
    margin: 0 8px;
    @media only screen and (max-width: 1020px) {
      height: 20px;
    }

    @media only screen and (max-width: 800px) {
      height: 15px;
      margin: 0 4px;
    }
  }
`

const Button = styled.div`
  position: absolute;
  bottom: 32px;
  border: none;
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 216px;
  height: 48px;
  border-radius: 100px;
  background-color: #ffffff;
  left: 50%;
  transform: translate(-50%);

  @media only screen and (max-width: 1400px) {
    width: 180px;
    height: 40px;
    font-size: 20px;
    bottom: 15px;
  }

  @media only screen and (max-width: 1020px) {
    width: 130px;
    height: 40px;
    font-size: 16px;
    bottom: 15px;
  }

  @media only screen and (max-width: 800px) {
    width: 100px;
    height: 25px;
    font-size: 12px;
  }

  & img {
    height: 48px;
    margin-right: 15px;

    @media only screen and (max-width: 1020px) {
      height: 28px;
      margin-right: 5px;
    }
    @media only screen and (max-width: 800px) {
      height: 17px;
      margin-right: 5px;
    }
  }
`

const BecomeMemberIcon = styled.img`
  @media (max-width: 800px) {
    width: 30px;
    height: 30px;
  }
`

const BackgroundTitle = styled.div`
  background: white;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2px;
  grid-template-rows: repeat(2, 1fr);
  aspect-ratio: 1 / 1;
  box-sizing: border-box;

  &:after {
    content: '';
    background: black;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.5;
  }
`

const BackgroundTile = styled.div`
  width: 99%;
  height: 99%;
  background: #dcdcdc;
  content: '';
  aspect-ratio: 1 / 1;
  max-width: 150px;
  max-height: 150px;

  & img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
    object-fit: contain;
    max-width: 150px;
    max-height: 150px;
  }
`

const contentMap = {
  room: {
    title: 'MyRoom',
    background: MyRoomBg,
    buttonLabel: 'Upload',
    icon: UploadIcon,
    route: '/myrooms'
  },
  decor: {
    title: 'MyDecor',
    background: MyDecorBg,
    buttonLabel: 'Upload',
    icon: UploadIcon,
    route: '/mydecor'
  },
  background: {
    title: 'Remove Background',
    background: RemoveBg,
    buttonLabel: 'Remove',
    icon: RemoveIcon
  },
  ar: {
    title: 'AR View',
    background: ArViewBg,
    buttonLabel: 'AR View',
    icon: ArViewIcon,
    description: '(Mobile Only)'
  },
  ruler: {
    title: 'AR Ruler',
    background: ARBg,
    buttonLabel: 'Measure',
    icon: ARIcon,
    description: '(Mobile Only)'
  },
  '3d': {
    title: '3D Model',
    background: DBg,
    buttonLabel: '3D View',
    icon: DIcon,
    description: '(Developing)'
  },
  becomeMember: {
    title: `Become<br />Member`,
    background: BecomeMemberBg,
    buttonLabel: 'Learn More',
    nonMemberOnly: true
  },
  myEvent: {
    title: 'MyEvent',
    background: MyEventBg,
    buttonLabel: 'Host',
    icon: UploadIcon,
    hideMemberOnly: true,
    nonMemberOnly: false
  }
}

const DecorateMyRoomCard = ({
  data: { type },
  setModalData,
  isMember,
  toggleRemoveBgModal
}) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const history = useHistory()
  const dispatch = useDispatch()
  const myrooms = useSelector(state => state.myroom.feed)
  const mydecor = useSelector(state => state.mydecor.feed)

  const {
    background,
    title,
    buttonLabel,
    icon,
    description,
    isActive,
    route,
    hideMemberOnly,
    nonMemberOnly
  } = contentMap[type]

  if (nonMemberOnly && isMember) return null

  const handleClick = () => {
    logEvent(`tile_${type}_click`, {})

    if (type === 'becomeMember') {
      if (isAuthenticated) dispatch(setShowMembershipModal(true))
      else dispatch(gotoSignUp())
      return
    }

    if (isMember) {
      if (type === 'background') {
        toggleRemoveBgModal()
        return
      } else if (route) {
        history.push(route)
        return
      }
    }

    setModalData(type)
  }

  const getBackground = () => {
    if (isAuthenticated) {
      if (type === 'room' && myrooms.length > 0) {
        return (
          <BackgroundTitle>
            {myrooms.map(item => (
              <BackgroundTile>
                <img
                  key={item.objectId}
                  src={item.cfImageUrl}
                  alt={title || ''}
                />
              </BackgroundTile>
            ))}
            <BackgroundTile />
            <BackgroundTile />
            <BackgroundTile />
            <BackgroundTile />
          </BackgroundTitle>
        )
      }

      if (type === 'decor' && mydecor.length > 0) {
        return (
          <BackgroundTitle>
            {mydecor.map(item => (
              <BackgroundTile>
                <img
                  key={item.objectId}
                  src={item.cfImageUrl}
                  alt={title || ''}
                />
              </BackgroundTile>
            ))}
            <BackgroundTile />
            <BackgroundTile />
            <BackgroundTile />
            <BackgroundTile />
          </BackgroundTitle>
        )
      }
    }

    return null
  }

  return (
    <Card onClick={handleClick} isActive={isActive}>
      <Image
        src={background}
        width={300}
        height={300}
        loading="lazy"
        sizes="(max-width: 800px) 140px, (max-width: 1024px) 240px, (max-width: 1200px) 240px, 300px"
      />
      {getBackground()}
      <Title>
        {type === 'becomeMember' && (
          <>
            <BecomeMemberIcon
              src={MembershipIcon}
              width="71px"
              height="71px"
              loading="lazy"
              alt="Membership"
              style={{ marginTop: -50 }}
            />
            <br />
          </>
        )}
        <span dangerouslySetInnerHTML={{ __html: title }}></span>
      </Title>
      <Description>{description}</Description>
      <Button>
        {icon && (
          <img
            width={31}
            height={48}
            src={icon}
            alt={buttonLabel}
            loading="lazy"
          />
        )}
        {buttonLabel}
      </Button>
      {!isMember && !hideMemberOnly && type !== 'becomeMember' && (
        <Membership>
          <img
            src={MembershipIcon}
            width="26px"
            height="26px"
            loading="lazy"
            alt="Membership"
          />
          Members Only
        </Membership>
      )}
    </Card>
  )
}

export default DecorateMyRoomCard
