import React from 'react'
import styled from 'styled-components'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`

const ModalContentContainer = ({ children, ...props }) => {
  return <Content {...props}>{children}</Content>
}

export default ModalContentContainer
